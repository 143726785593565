/*

Template: Appino - Responsive App Landing Page
Author: iqonicthemes.in
Version: 1.0
Design and Developed by: iqonicthemes.in

NOTE: This is main stylesheet of template, This file contains the styling for the actual Template. Please do not change anything here! write in a custom.css file if required!

*/

/*================================================
[  Table of contents  ]
================================================

:: General
:: Back to Top
:: Loader
:: Buttons
:: Div Shadow
:: Page Section Margin Padding 
:: Text Weight 
:: Text Color 
:: Background Color 
:: BG Effect
:: Background Gradient 
:: Section Title
:: Header
:: Banner
:: Feature
:: About us
:: Special Features
:: App Screenshots
:: The Appino Great Feature
:: TESTIMONIAL
:: TEAM
:: Counter
:: Asked Questions
:: Price Table
:: BLOG
:: Our clients
:: Newsletter
:: Our Info
:: Map
:: Get in Touch
:: Footer
:: Breadcrumb Inner Page
:: Blog Page
:: Blog - SideBar
:: 404 Error
:: Coming Soon

======================================
[ End table content ]
======================================*/

/*---------------------------------------------------------------------
                               General
-----------------------------------------------------------------------*/

body { font-family: 'Open Sans', sans-serif; font-weight: normal; font-style: normal; font-size: 14px; line-height: 24px; color: #666666; overflow-x: hidden; }
a, .button { transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; color: #333333; }
a:focus { text-decoration: none !important; }
a:focus, a:hover { color: rgba(225, 35, 32, 0.9); text-decoration: none !important; }
a, .button, input { outline: medium none !important; color: rgba(225, 35, 32, 0.9); }
h1, h2, h3, h4, h5, h6 { font-family: 'Raleway', sans-serif; font-weight: normal; color: #333333; margin-top: 0px; margin-bottom: 0px; }
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { color: inherit; }
h1 { font-size: 54px; font-style: normal; line-height: 66px; }
h2 { font-size: 36px; font-style: normal; line-height: 44px; }
h3 { font-size: 32px; font-style: normal; line-height: 42px; }
h4 { font-size: 24px; font-style: normal; line-height: 24px; }
h5 { font-size: 20px; font-style: normal; line-height: 30px; }
h6 { font-size: 18px; font-style: normal; line-height: 28px; }
.lead { font-size: 16px; line-height: 24px; margin: 0; }
ul { margin: 0px; padding: 0px; }
li { list-style: none; }
hr { margin: 0; padding: 0px; border-bottom: 1px solid #e0e0e0; border-top: 0px; }
label { font-size: 15px; font-weight: 400; color: #aaaaaa; }
.label { color: #fff !important; font-size: 9px !important; }
blockquote { border-left: 5px solid rgba(225, 35, 32, 0.9); font-size: 16px; }
*::-moz-selection { background: rgba(225, 35, 32, 0.9); color: #fff; text-shadow: none; }
::-moz-selection { background: rgba(225, 35, 32, 0.9); color: #fff; text-shadow: none; }
::selection { background: rgba(225, 35, 32, 0.9); color: #fff; text-shadow: none; }
.valign, .valign > [class*='col-'] { display: table-cell; float: none; }
[data-valign-overlay="top"] { vertical-align: top; }
[data-valign-overlay="bottom"] { vertical-align: bottom; }
[data-valign-overlay="middle"] { vertical-align: middle; }
.no-gutter > [class*='col-'] { padding-right: 0; padding-left: 0; }
.row-eq-height { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; }
.no-padding { padding: 0px !important; }
.no-border { border: none !important; }

/*---------------------------------------------------------------------
                               Back to Top
-----------------------------------------------------------------------*/
#back-to-top .top { z-index: 999; position: fixed; margin: 0px; color: #fff; background: rgba(225, 35, 32, 0.9); position: fixed; bottom: 25px; right: 25px; z-index: 999; font-size: 26px; width: 50px; height: 50px; text-align: center; line-height: 50px; border-radius: 90px; -webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; transition: all .3s ease-in-out; }
#back-to-top .top:hover { background: rgba(225, 35, 32, 0.7); color: #fff; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); }

/*---------------------------------------------------------------------
                                Loader
-----------------------------------------------------------------------*/
#loading { background-color: #ffffff; height: 100%; width: 100%; position: fixed; margin-top: 0px; top: 0px; left: 0px; bottom: 0px; overflow: hidden !important; right: 0px; z-index: 999999; }
.boxLoading { width: 50px; height: 50px; margin: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0; }
.boxLoading:before { content: ''; width: 50px; height: 5px; background: #000; opacity: 0.1; position: absolute; top: 59px; left: 0; border-radius: 50%; animation: shadow .5s linear infinite; }
.boxLoading:after { content: ''; width: 50px; height: 50px; background: #fff; animation: animate .5s linear infinite; position: absolute; top: 0; left: 0; border-radius: 3px; }
@keyframes animate {
	17% { border-bottom-right-radius: 3px; }
	25% { transform: translateY(9px) rotate(22.5deg); }
	50% { transform: translateY(18px) scale(1, 0.9) rotate(45deg); border-bottom-right-radius: 40px; }
	75% { transform: translateY(9px) rotate(67.5deg); }
	100% { transform: translateY(0) rotate(90deg); }
}
@keyframes shadow {
	0%, 100% { transform: scale(1, 1); }
	50% { transform: scale(1.2, 1); }
}

/*----------------------------------------------------------------------
                        Buttons
-----------------------------------------------------------------------*/
.button { color: #fff; padding: 12px 36px; font-weight: 600; border: none; position: relative; font-family: 'Raleway', sans-serif; display: inline-block; text-transform: uppercase; -webkit-border-radius: 90px; -moz-border-radius: 90px; border-radius: 90px; margin: 2px; background-image: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 51%, rgba(225, 35, 32, 0.7) 100%); background-size: 200% auto; flex: 1 1 auto; }
.button:hover, .button:focus { color: #ffffff; background-position: right center; -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1); }

/* button White */
.button.bt-white { background: #fff; color: #666666; box-shadow: none; }
.button.bt-white:hover, .button.bt-white:focus { background: #333333; color: #fff; }

/* button White Icon */
.button.button-icon { line-height: 34px; border: 2px solid #fff; background: transparent; padding: 8px 35px; }
.button.button-icon:hover { color: rgba(225, 35, 32, 0.9); border: 2px solid #fff; background: #fff; }
.button.button-icon i { font-size: 32px; display: inline-block; float: left; margin-right: 10px; }

/*----------------------------------------------------------------------
                                Div Shadow
-----------------------------------------------------------------------*/
.iq-box-shadow { position: relative; z-index: 9; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); }

/*---------------------------------------------------------------------
                    Page Section Margin Padding 
---------------------------------------------------------------------*/

/*----------------
Section padding 
----------------*/
.overview-block-ptb { padding: 100px 0; }
.overview-block-pt { padding: 100px 0 0; }
.overview-block-pb { padding: 0 0 100px; }

/*----------------
Padding All 
----------------*/
.iq-pall { padding: 0px; }
.iq-pall-10 { padding: 10px; }
.iq-pall-15 { padding: 15px; }
.iq-pall-20 { padding: 20px; }
.iq-pall-25 { padding: 25px; }
.iq-pall-30 { padding: 30px; }
.iq-pall-40 { padding: 40px; }
.iq-pall-50 { padding: 50px; }
.iq-pall-60 { padding: 60px; }
.iq-pall-70 { padding: 70px; }
.iq-pall-80 { padding: 80px; }
.iq-pall-90 { padding: 90px; }
.iq-pall-100 { padding: 100px; }

/*----------------
Padding Top and Bottom 
----------------*/
.iq-ptb-0 { padding: 0; }
.iq-ptb-10 { padding: 10px 0; }
.iq-ptb-15 { padding: 15px 0; }
.iq-ptb-20 { padding: 20px 0; }
.iq-ptb-25 { padding: 25px 0; }
.iq-ptb-30 { padding: 30px 0; }
.iq-ptb-40 { padding: 40px 0; }
.iq-ptb-50 { padding: 50px 0; }
.iq-ptb-60 { padding: 60px 0; }
.iq-ptb-70 { padding: 70px 0; }
.iq-ptb-80 { padding: 80px 0; }
.iq-ptb-90 { padding: 90px 0; }
.iq-ptb-100 { padding: 100px 0; }

/*----------------
Padding Left and right 
----------------*/
.iq-plr-0 { padding: 0; }
.iq-plr-10 { padding: 0 10px; }
.iq-plr-15 { padding: 0 15px; }
.iq-plr-20 { padding: 0 20px; }
.iq-plr-25 { padding: 0 25px; }
.iq-plr-30 { padding: 0 30px; }
.iq-plr-40 { padding: 0 40px; }
.iq-plr-50 { padding: 0 50px; }
.iq-plr-60 { padding: 0 60px; }
.iq-plr-70 { padding: 0 70px; }
.iq-plr-80 { padding: 0 80px; }
.iq-plr-90 { padding: 0 90px; }
.iq-plr-100 { padding: 0 100px; }

/*----------------
Padding top 
----------------*/
.iq-pt-0 { padding-top: 0px; }
.iq-pt-10 { padding-top: 10px; }
.iq-pt-15 { padding-top: 15px; }
.iq-pt-20 { padding-top: 20px; }
.iq-pt-25 { padding-top: 25px; }
.iq-pt-30 { padding-top: 30px; }
.iq-pt-40 { padding-top: 40px; }
.iq-pt-50 { padding-top: 50px; }
.iq-pt-60 { padding-top: 60px; }
.iq-pt-70 { padding-top: 70px; }
.iq-pt-80 { padding-top: 80px; }
.iq-pt-90 { padding-top: 90px; }
.iq-pt-100 { padding-top: 100px; }

/*----------------
Padding Bottom 
----------------*/
.iq-pb-0 { padding-bottom: 0px; }
.iq-pb-10 { padding-bottom: 10px; }
.iq-pb-15 { padding-bottom: 15px; }
.iq-pb-20 { padding-bottom: 20px; }
.iq-pb-25 { padding-bottom: 25px; }
.iq-pb-30 { padding-bottom: 30px; }
.iq-pb-40 { padding-bottom: 40px; }
.iq-pb-50 { padding-bottom: 50px; }
.iq-pb-60 { padding-bottom: 60px; }
.iq-pb-70 { padding-bottom: 70px; }
.iq-pb-80 { padding-bottom: 80px; }
.iq-pb-90 { padding-bottom: 90px; }
.iq-pb-100 { padding-bottom: 100px; }

/*----------------
Padding Left 
----------------*/
.iq-pl-0 { padding-left: 0; }
.iq-pl-10 { padding-left: 10px; }
.iq-pl-15 { padding-left: 15px; }
.iq-pl-20 { padding-left: 20px; }
.iq-pl-25 { padding-left: 25px; }
.iq-pl-30 { padding-left: 30px; }
.iq-pl-40 { padding-left: 40px; }
.iq-pl-50 { padding-left: 50px; }
.iq-pl-60 { padding-left: 60px; }
.iq-pl-70 { padding-left: 70px; }
.iq-pl-80 { padding-left: 80px; }
.iq-pl-90 { padding-left: 90px; }
.iq-pl-100 { padding-left: 100px; }

/*----------------
Padding Right 
----------------*/
.iq-pr-0 { padding-right: 0px; }
.iq-pr-10 { padding-right: 10px; }
.iq-pr-15 { padding-right: 15px; }
.iq-pr-20 { padding-right: 20px; }
.iq-pr-25 { padding-right: 25px; }
.iq-pr-30 { padding-right: 30px; }
.iq-pr-40 { padding-right: 40px; }
.iq-pr-50 { padding-right: 50px; }
.iq-pr-60 { padding-right: 60px; }
.iq-pr-70 { padding-right: 70px; }
.iq-pr-80 { padding-right: 80px; }
.iq-pr-90 { padding-right: 90px; }
.iq-pr-100 { padding-right: 100px; }

/*----------------
Margin All 
----------------*/
.iq-mall-0 { margin: 0; }
.iq-mall-10 { margin: 10px; }
.iq-mall-15 { margin: 15px; }
.iq-mall-20 { margin: 20px; }
.iq-mall-25 { margin: 25px; }
.iq-mall-30 { margin: 30px; }
.iq-mall-40 { margin: 40px; }
.iq-mall-50 { margin: 50px; }
.iq-mall-60 { margin: 60px; }
.iq-mall-70 { margin: 70px; }
.iq-mall-80 { margin: 80px; }
.iq-mall-90 { margin: 90px; }
.iq-mall-100 { margin: 100px; }

/*----------------
Margin Top and Bottom 
----------------*/
.iq-mtb-0 { margin: 0; }
.iq-mtb-10 { margin: 10px 0; }
.iq-mtb-15 { margin: 15px 0; }
.iq-mtb-20 { margin: 20px 0; }
.iq-mtb-25 { margin: 25px 0; }
.iq-mtb-30 { margin: 30px 0; }
.iq-mtb-40 { margin: 40px 0; }
.iq-mtb-50 { margin: 50px 0; }
.iq-mtb-60 { margin: 60px 0; }
.iq-mtb-70 { margin: 70px 0; }
.iq-mtb-80 { margin: 80px 0; }
.iq-mtb-90 { margin: 90px 0; }
.iq-mtb-100 { margin: 100px 0; }

/*----------------
Margin Left and Right 
----------------*/
.iq-mlr-0 { margin: 0; }
.iq-mlr-10 { margin: 0 10px; }
.iq-mlr-15 { margin: 0 15px; }
.iq-mlr-20 { margin: 0 20px; }
.iq-mlr-25 { margin: 0 25px; }
.iq-mlr-30 { margin: 0 30px; }
.iq-mlr-40 { margin: 0 40px; }
.iq-mlr-50 { margin: 0 50px; }
.iq-mlr-60 { margin: 0 60px; }
.iq-mlr-70 { margin: 0 60px; }
.iq-mlr-80 { margin: 0 80px; }
.iq-mlr-90 { margin: 0 80px; }
.iq-mlr-100 { margin: 0 100px; }

/*----------------
Margin Top 
----------------*/
.iq-mt-0 { margin-top: 0px; }
.iq-mt-10 { margin-top: 10px; }
.iq-mt-15 { margin-top: 15px; }
.iq-mt-20 { margin-top: 20px; }
.iq-mt-25 { margin-top: 25px; }
.iq-mt-30 { margin-top: 30px; }
.iq-mt-40 { margin-top: 40px; }
.iq-mt-50 { margin-top: 50px; }
.iq-mt-60 { margin-top: 60px; }
.iq-mt-70 { margin-top: 70px; }
.iq-mt-80 { margin-top: 80px; }
.iq-mt-90 { margin-top: 90px; }
.iq-mt-100 { margin-top: 100px; }

/*----------------
Margin Bottom 
----------------*/
.iq-mb-0 { margin-bottom: 0px; }
.iq-mb-10 { margin-bottom: 10px; }
.iq-mb-15 { margin-bottom: 15px; }
.iq-mb-20 { margin-bottom: 20px; }
.iq-mb-25 { margin-bottom: 25px; }
.iq-mb-30 { margin-bottom: 30px; }
.iq-mb-40 { margin-bottom: 40px; }
.iq-mb-50 { margin-bottom: 50px; }
.iq-mb-60 { margin-bottom: 60px; }
.iq-mb-70 { margin-bottom: 70px; }
.iq-mb-80 { margin-bottom: 80px; }
.iq-mb-90 { margin-bottom: 90px; }
.iq-mb-100 { margin-bottom: 100px; }

/*----------------
Margin Left 
----------------*/
.iq-ml-0 { margin-left: 0px; }
.iq-ml-10 { margin-left: 10px; }
.iq-ml-15 { margin-left: 15px; }
.iq-ml-20 { margin-left: 20px; }
.iq-ml-25 { margin-left: 25px; }
.iq-ml-30 { margin-left: 30px; }
.iq-ml-40 { margin-left: 40px; }
.iq-ml-50 { margin-left: 50px; }
.iq-ml-60 { margin-left: 60px; }
.iq-ml-70 { margin-left: 70px; }
.iq-ml-80 { margin-left: 80px; }
.iq-ml-90 { margin-left: 90px; }
.iq-ml-100 { margin-left: 100px; }

/*----------------
Margin Right
----------------*/
.iq-mr-0 { margin-right: 0px; }
.iq-mr-10 { margin-right: 10px; }
.iq-mr-15 { margin-right: 15px; }
.iq-mr-20 { margin-right: 20px; }
.iq-mr-25 { margin-right: 25px; }
.iq-mr-30 { margin-right: 30px; }
.iq-mr-40 { margin-right: 40px; }
.iq-mr-50 { margin-right: 50px; }
.iq-mr-60 { margin-right: 60px; }
.iq-mr-70 { margin-right: 70px; }
.iq-mr-80 { margin-right: 80px; }
.iq-mr-90 { margin-right: 90px; }
.iq-mr-100 { margin-right: 100px; }

/*---------------------------------------------------------------------
                            Text Weight 
-----------------------------------------------------------------------*/
.iq-tw-1 { font-weight: 100; }
.iq-tw-2 { font-weight: 200; }
.iq-tw-3 { font-weight: 300; }
.iq-tw-4 { font-weight: 400; }
.iq-tw-5 { font-weight: 500; }
.iq-tw-6 { font-weight: 600; }
.iq-tw-7 { font-weight: 700; }
.iq-tw-8 { font-weight: 800; }
.iq-tw-9 { font-weight: 900; }

/*---------------------------------------------------------------------
                            Text Color 
-----------------------------------------------------------------------*/
.iq-font-blue { color: rgba(225, 35, 32, 0.9); }
.iq-font-light-blue { color: rgba(225, 35, 32, 0.7); }
.iq-font-white { color: #ffffff; }
.iq-font-black { color: #ffffff; }
.iq-font-grey { color: #ffffff; }
.iq-font-light { color: #ffffff; }

/*---------------------------------------------------------------------
                          Background Color 
-----------------------------------------------------------------------*/
.white-bg { background: #ffffff; }
.dark-bg { background: #2c3e50; }
.grey-bg { background: #f5f7fb; }
.green-bg { background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }

/*---------------------------------------------------------------------
                             BG Effect
-----------------------------------------------------------------------*/
.iq-bg { background-color: rgba(0, 0, 0, 0); background-origin: initial; background-position: center center; background-repeat: no-repeat; background-size: cover !important; position: relative; z-index: 0; }
.iq-bg-fixed { background-color: rgba(0, 0, 0, 0); background-origin: initial; background-position: center center; background-repeat: no-repeat; background-size: cover !important; position: relative; z-index: 0; background-attachment: fixed !important; }

/*---------------------------------------------------------------------
                       Background Gradient 
---------------------------------------------------------------------*/
.iq-over-black-80:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-over-black-90:before { content: ""; height: 100%; left: 0; position: absolute; top: 0; width: 100%; z-index: -1; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
[class*='iq-over-']:before { z-index: 0; }

/*---------------------------------------------------------------------
                           Section Title
-----------------------------------------------------------------------*/
.heading-title { margin-bottom: 80px; text-align: center; }
.heading-title .title { position: relative; }
.heading-title .divider { border: 2px solid rgba(225, 35, 32, 0.7); position: relative; display: inline-block; width: 30px; margin-top: 30px; }
.heading-title .divider:after { position: absolute; content: ""; top: -8px; left: 0; width: 50px; height: 1px; background: rgba(225, 35, 32, 0.9); transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.heading-title .divider:before { position: absolute; content: ""; bottom: -8px; right: 0; width: 50px; height: 1px; background: rgba(225, 35, 32, 0.7); transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.heading-title p { padding: 0 100px; display: block; margin-bottom: 50px; margin-top: 20px; }

/* heading Title border White */
.heading-title .divider.white { border-color: #fff; }
.heading-title .divider.white:after { background: #fff; }
.heading-title .divider.white:before { background: #fff; }

/* heading Title Hover */
section:hover .heading-title .divider:after { width: 70px; }
section:hover .heading-title .divider:before { width: 70px; }

/* heading Title Left */
.heading-left { position: relative; padding-bottom: 12px; margin-bottom: 25px; }
.heading-left:before { position: absolute; content: ""; bottom: 0; left: 0; width: 60px; height: 2px; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }

/* heading Title Left White */
.heading-left.white:before { background: #fff; }

/*---------------------------------------------------------------------
                            Header
-----------------------------------------------------------------------*/
header { position: fixed; display: inline-block; width: 100%; top: 0; left: 0; z-index: 999; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
header .navbar-header .navbar-brand img { height: 55px; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
header .navbar-default { background: none; border: none; border-radius: 0px; min-height: inherit; margin: 20px 0; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
header .navbar-default .navbar-brand { height: inherit; padding: 0; }
header .navbar-nav { margin-top: 14px; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
header .navbar-nav > li { margin: 0 20px 0 0; }
header .navbar-default .navbar-nav li:last-child { margin-right: 0; }
header .navbar-default .navbar-nav > li > a { color: #ffffff; padding: 10px 0; font-family: 'Raleway', sans-serif; font-size: 15px; }
header .navbar-default .navbar-nav > li > a:hover, header .navbar-default .navbar-nav > li > a:focus, header .navbar-default .navbar-nav > li.active > a, header .navbar-default .navbar-nav > li.active > a:focus, header .navbar-default .navbar-nav > li.active > a:hover { color: #fff; background: none; box-shadow: none; }
header .nav > li a::before { background: #fff; bottom: 0; content: ""; height: 2px; left: 0; position: absolute; width: 0; transition: all 0.3s ease-out 0s; }
header .nav > li.active > a::before, header .nav > li:hover > a::before, header .nav > li > a:hover::before { width: 100%; }

/* Header sticky */
header.affix { -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(225, 35, 32, 0.9); background: header; background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
header.affix .navbar-header .navbar-brand img { height: 55px; }
header.affix .navbar-default { margin: 10px 0; }
header.affix .navbar-nav { margin-top: 1px; }

/*---------------------------------------------------------------------
                            Banner
-----------------------------------------------------------------------*/
.banner-text { color: #fff; font-family: 'Raleway', sans-serif; margin: 16% 0 8%; }
.img-one { width: 80%; }
.banner-text h1 { font-size: 100px; line-height: 120px; margin-bottom: 30px; text-shadow: 0px 20px 10px rgba(0, 0, 0, 0.1); margin-top: 15%; letter-spacing: 2px; }
.banner-text h1 small { font-size: 18px; display: block; text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1); }
.banner-text .link h5 { margin-bottom: 20px; position: relative; display: inline-block; }
.banner-text .link h5:before { position: absolute; top: 20px; right: -25px; content: ""; background: url('../images/arrow.png') no-repeat 0 0; width: 61px; height: 58px; }
.banner-text .link li { margin: 0 5px; }
.banner-text .link li a { font-size: 40px; color: #fff; text-shadow: 0px 2px 10px rgba(0, 0, 195, 0.15); }
.banner .carousel-indicators { bottom: inherit; left: inherit; width: 25px; right: 0; top: 50%; margin-top: -60px; margin-right: 15px; }
.banner .carousel-indicators li { border: none; background: rgba(255, 255, 255, 0.6); padding-left: 0; }
.banner .carousel-indicators .active { background: rgba(255, 255, 255, 1.0); }

/* Animation delays */
.banner-text h1 { animation-delay: 0.5s; }
.banner-text h5 { animation-delay: 1s; }
.banner-text ul.list-inline { animation-delay: 1.5s; }
.banner-text img { animation-delay: 2s; }

/*---------------------------------------------------------------------
                            Feature
-----------------------------------------------------------------------*/
.iq-fancy-box { position: relative; padding: 40px 30px; overflow: hidden; -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
.iq-fancy-box:before { position: absolute; content: ""; left: -80px; top: 0; height: 2px; width: 70px; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-fancy-box:after { position: absolute; content: ""; right: -80px; bottom: 0; height: 2px; width: 70px; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-fancy-box i { font-size: 60px; }
.iq-fancy-box h4 { margin: 30px 0 15px; }

/* fancy box Hover */
.iq-fancy-box:hover:before { left: 0; }
.iq-fancy-box:hover:after { right: 0; }

/*---------------------------------------------------------------------
                            About us
-----------------------------------------------------------------------*/
.iq-about-bg:before { background: url('../../images/about/01.jpg'); position: absolute; right: 100%; top: 0; width: 100%; content: ""; height: 100%; background-attachment: fixed; }
.iq-about-bg:after { position: absolute; right: 100%; top: 0; width: 100%; content: ""; height: 100%; background: rgba(225, 35, 32, 0.9); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-about .about-img { position: relative; -moz-transform: scale(1.1); -webkit-transform: scale(1.1); transform: scale(1.1); z-index: 9; height: 100%; }
.iq-about .about-img:before { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5) }
.iq-about .about-img img { width: 100%; height: 100%; }
.iq-video { position: absolute; left: 50%; top: 50%; background: #fff; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 80px; height: 80px; text-align: center; font-size: 38px; color: rgba(225, 35, 32, 0.7); border-radius: 100%; line-height: 2.1; z-index: 9; }
.iq-video i { margin-left: 7px; }
.iq-video:focus, .iq-video:hover { color: rgba(225, 35, 32, 0.9); }
.iq-waves { position: absolute; width: 384px; width: 24rem; height: 384px; height: 24rem; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); z-index: 2; }
.iq-waves .waves { position: absolute; width: 384px; width: 24rem; height: 384px; height: 24rem; background: rgba(255, 255, 255, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; border-radius: 320px; background-clip: padding-box; -webkit-animation: waves 3s ease-in-out infinite; animation: waves 3s ease-in-out infinite; }
.iq-waves .wave-1 { -webkit-animation-delay: 0s; animation-delay: 0s; }
.iq-waves .wave-2 { -webkit-animation-delay: 1s; animation-delay: 1s; }
.iq-waves .wave-3 { -webkit-animation-delay: 2s; animation-delay: 2s; }
@-webkit-keyframes waves {
	0% { -webkit-transform: scale(0.2, 0.2); transform: scale(0.2, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
	50% { opacity: 0.9; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
	100% { -webkit-transform: scale(0.9, 0.9); transform: scale(0.9, 0.9); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
}
@keyframes waves {
	0% { -webkit-transform: scale(0.2, 0.2); transform: scale(0.2, 0.2); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
	50% { opacity: 0.9; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)"; }
	100% { -webkit-transform: scale(0.9, 0.9); transform: scale(0.9, 0.9); opacity: 0; -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; }
}

/*---------------------------------------------------------------------
                            Special Features
-----------------------------------------------------------------------*/
.iq-amazing-tab .nav-tabs { border: none; }
.iq-amazing-tab .nav-tabs li { margin-top: 50px; margin-bottom: 0; float: left; display: inline-block; width: 100%; }
.iq-amazing-tab .nav-tabs li a { margin: 0; line-height: normal; border: none; border-radius: 0; padding: 0; color: #666; transition: all 0s ease-in-out; transition: all 0s ease-in-out; -moz-transition: all 0s ease-in-out; -ms-transition: all 0s ease-in-out; -o-transition: all 0s ease-in-out; -webkit-transition: all 0s ease-in-out; }
.iq-amazing-tab .nav-tabs li.active a, .iq-amazing-tab .nav-tabs li.active a:hover, .iq-amazing-tab .nav-tabs li.active a:focus { color: #fff; border: none; left: -20px; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-amazing-tab .nav-tabs li a:hover { color: #fff; border: none; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-amazing-tab .nav-tabs li.active a .iq-fancy-box-01:before { content: ""; position: absolute; top: 50%; margin-top: -10px; left: -10px; width: 20px; height: 20px; display: inline-block; background: rgba(225, 35, 32, 0.7); border-radius: 90px; -webkit-box-shadow: 0px 0px 0px 5px rgba(247, 247, 247, 1); -moz-box-shadow: 0px 0px 0px 5px rgba(247, 247, 247, 1); box-shadow: 0px 0px 0px 5px rgba(247, 247, 247, 1); }
.iq-amazing-tab .nav-tabs li.active a .iq-fancy-box-01:after { content: ""; position: absolute; z-index: 9; top: 50%; margin-top: -1px; right: 100%; width: 100px; height: 2px; display: inline-block; background: rgba(225, 35, 32, 0.9); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.9)), color-stop(100%, rgba(225, 35, 32, 0.7))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e50e2', endColorstr='#09b3ef', GradientType=1); }
.nav-tabs li.active a.round-right, .nav-tabs li.active a.round-right:hover, .nav-tabs li.active a.round-right:focus { right: -20px; left: inherit; }
.iq-amazing-tab .nav-tabs li.active a.round-right .iq-fancy-box-01:before { background: rgba(225, 35, 32, 0.9); right: -10px; left: inherit; }
.iq-amazing-tab .nav-tabs li.active a.round-right .iq-fancy-box-01:after { right: inherit; left: 100%; background: rgba(225, 35, 32, 0.9); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.9)), color-stop(100%, rgba(225, 35, 32, 0.7))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.9) 0%, rgba(225, 35, 32, 0.7) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e50e2', endColorstr='#09b3ef', GradientType=1); }
.nav-tabs li.active a h4, .nav-tabs li.active a:hover h4, .nav-tabs li.active a:focus h4, .nav-tabs li.active a i, .nav-tabs li.active a:hover i, .nav-tabs li.active a:focus i { color: #fff; }
.iq-amazing-tab .nav-tabs li a:hover h4 { color: #fff; }
.iq-amazing-tab .nav-tabs li a:hover i { background: none; color: #fff; -webkit-background-clip: text; -webkit-text-fill-color: snow; }
.iq-amazing-tab .tab-content { background: url('../../images/screenshots/img_07.png') no-repeat top center; padding: 87px 36px 99px 44px; background-size: 100% 100%; }
.iq-amazing-tab .tab-content .tab-pane img { width: 100%; }

/* fancy box 01 */
.iq-fancy-box-01 { padding: 10px 30px; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); }
.iq-fancy-box-01 i { font-size: 50px; float: left; margin-right: 15px; color: rgba(225, 35, 32, 0.7); }
.iq-fancy-box-01 h4 { margin: 15px 0 0; float: left; display: inline-block; }
.fancy-content-01 { display: inline-block; width: 100%; }
.fancy-content-01 p { line-height: 24px; }

/* fancy box 01 Right icon */
.iq-fancy-box-01.text-right i { float: right; margin-left: 15px; margin-right: 0; }
.iq-fancy-box-01.text-right h4 { display: inline-block; float: none; }

/*---------------------------------------------------------------------
                             App Screenshots
-----------------------------------------------------------------------*/
.iq-app:before { content: ""; position: absolute; top: 0; left: 0; background: rgba(0, 0, 0, 0.7); height: 100%; width: 100%; }
.iq-app-info { padding: 30px 15% 0% 29%; }
.home-screen-slide { position: relative; padding: 72px 0px 76px 36px; margin-left: -100px; }
.home-screen-slide:before { background: url('../../images/screenshots/img_07.png') no-repeat 0 0; background-size: 100% 100%; content: ""; position: absolute; left: 0; top: 0; height: 100%; width: 301px; }
.home-screen-slide .owlcarousel .item { width: 236px; }
.iq-app .owlcarousel .owl-item a { position: relative; display: inline-block; width: 100%; }
.iq-app .owlcarousel .owl-item a:before { position: absolute; left: 0; top: 0; width: 100%; height: 100%; content: "\f4a4"; font-size: 30px; color: #fff; line-height: 15; font-family: Ionicons; text-align: center; vertical-align: center; background: rgba(225, 35, 32, 0.7); z-index: 9; opacity: 0; }
.iq-app .owlcarousel .owl-item a:hover:before { opacity: 1; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }

/*---------------------------------------------------------------------
                       The Appino Great Feature
-----------------------------------------------------------------------*/
.iq-fancy-box-02 { padding: 40px 15px 25px 15px; overflow: hidden; position: relative; -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
.iq-fancy-box-02 .iq-icon { border-radius: 90px; display: inline-block; height: 70px; width: 70px; line-height: 70px; text-align: center; color: #ffffff; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-fancy-box-02 .iq-icon i { font-size: 36px; }
.iq-fancy-box-02 .iq-icon span { font-size: 30px; }
.iq-fancy-box-02 .fancy-content h5 { z-index: 9; position: relative; }
.iq-fancy-box-02 .iq-big-title { color: #f0f0f0; font-size: 70px; position: absolute; top: 50%; left: 50%; font-weight: 600; margin-top: -10px; margin-left: -40px; opacity: 0.6; }

/*---------------------------------------------------------------------
                          TESTIMONIAL
-----------------------------------------------------------------------*/
.iq-loved-customers .owlcarousel .owl-item .item { padding: 10px 10px 20px; }
.iq-client { position: relative; margin-top: 40px; padding: 0 30px 15px; -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); }
.iq-client:before { position: absolute; content: ""; right: 0; top: 0; height: 2px; width: 100px; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-client .client-img { width: 80px; height: 80px; display: inline-block; border-radius: 90px; margin-top: -40px; }
.client-name { padding-left: 60px; position: relative; }
.client-name:before { content: "\f10d"; font-family: FontAwesome; position: absolute; bottom: 0px; left: 0; font-size: 44px; color: #f0f0f0; line-height: normal; }
.client-info p { line-height: 28px; }
.owlcarousel .owl-nav { display: block; position: absolute; text-indent: inherit; top: 50%; transform: translateY(-50%); -webkit-transform: translateY(-50%); -o-transform: translateY(-50%); -ms-transform: translateY(-50%); -moz-transform: translateY(-50%); left: 0; width: 100%; cursor: pointer; z-index: 999; }
.owlcarousel .owl-nav .owl-prev { display: block; position: absolute; text-align: center; text-indent: inherit; left: -8%; width: auto; cursor: pointer; -webkit-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; -moz-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; -ms-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; -o-transition: opacity 0.3s ease 0s, left 0.3s ease 0s; transition: opacity 0.3s ease 0s, left 0.3s ease 0s; }
.owlcarousel .owl-nav .owl-next { display: block; position: absolute; text-align: center; text-indent: inherit; right: -8%; width: auto; cursor: pointer; -webkit-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; -moz-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; -ms-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; -o-transition: opacity 0.3s ease 0s, right 0.3s ease 0s; transition: opacity 0.3s ease 0s, right 0.3s ease 0s; }
.owlcarousel .owl-nav i { font-size: 24px; border-radius: 50%; width: 44px; height: 44px; line-height: 42px; padding-left: 0px; display: inline-block; color: #f5f7fb; background: #e7e7e7; font-weight: normal; text-align: center; -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }
.owlcarousel .owl-nav i:hover { background: rgba(225, 35, 32, 0.9); color: #f5f7fb; }

/*---------------------------------------------------------------------
                          TEAM
-----------------------------------------------------------------------*/
.iq-team { position: relative; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); }
.iq-team:before { position: absolute; content: ""; top: 0; left: 0; width: 100%; height: 100%; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); opacity: 0; }
.iq-team-info { position: absolute; top: 50%; left: 0; margin-top: -45px; width: 100%; display: inline-block; color: #fff; opacity: 0; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.team-post { margin: 5px 0 0; display: inline-block; width: 100%; }
.share { position: relative; width: 100%; }
.share span { display: inline-block; font-weight: 600; text-transform: uppercase; opacity: 1; pointer-events: none; position: absolute; top: 0px; left: 50%; margin-left: -70px; border-radius: 90px; padding: 8px 20px; background: #fff; color: rgba(225, 35, 32, 0.7); transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.share nav { font-size: 0; }
.share a { line-height: 40px; width: 40px; text-align: center; display: inline-block; color: #000; overflow: hidden; opacity: 1; margin: 0 -20px; background: #fff; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.share a:nth-child(1) { border-top-left-radius: 40px; border-bottom-left-radius: 40px; margin-left: 0; }
.share a:nth-child(1):hover { background-color: rgba(225, 35, 32, 0.7); }
.share a:nth-child(2):hover { background-color: rgba(225, 35, 32, 0.9); }
.share a:nth-child(3):hover { background-color: #ea4335; }
.share a:nth-child(4) { border-top-right-radius: 40px; border-bottom-right-radius: 40px; margin-right: 0; }
.share a:nth-child(4):hover { background-color: #000000; }
.share:hover span, .share.hover span { opacity: 0; }
.share:hover a, .share.hover a { border-radius: 50%; margin: 0 5px; color: rgba(225, 35, 32, 0.7); font-size: 16px; }
.share:hover a:hover, .share.hover a:hover { color: #fff; }
.iq-team:hover:before { opacity: 0.8; }
.iq-team:hover .iq-team-info { opacity: 1; }

/*---------------------------------------------------------------------
                          Counter
-----------------------------------------------------------------------*/
.counter { position: relative; font-family: 'Roboto', sans-serif; min-height: 60px; }
.counter i { font-size: 40px; line-height: 45px; display: inline-block; }
.counter i:before { font-size: 60px; }
.counter span { display: block; font-size: 36px; margin: 10px 0; margin-bottom: 5px; line-height: 40px; }
.counter label { font-weight: 600; font-size: 15px; position: relative; margin: 10px 0; margin-bottom: 0; text-transform: uppercase; }

/*---------------------------------------------------------------------
                        Asked Questions
-----------------------------------------------------------------------*/
.iq-accordion .ad-title { position: relative; padding: 15px 40px 15px 65px; font-size: 16px; line-height: normal; cursor: pointer; background-color: #f5f7fb; display: block; text-align: left; color: #2c3e50; text-decoration: none; border-radius: 4px; }
.iq-accordion .ad-title:before { -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); cursor: pointer; content: "\f217"; font-family: "Ionicons"; position: absolute; top: 0; right: 0; display: block; padding: 14px 20px; color: #fff; font-size: 16px; line-height: 24px; height: 100%; font-weight: normal; -webkit-transition: all 0.25s ease-in-out 0s; -moz-transition: all 0.25s ease-in-out 0s; transition: all 0.25s ease-in-out 0s; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.ad-icon { position: absolute; top: 0; left: 0; padding: 8px 14px; font-size: 26px; height: 100%; color: #fff; -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.06); background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(45deg, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-accordion .ad-active .ad-title:before { content: "\f208"; font-family: "Ionicons"; }
.iq-accordion .ad-title> i { margin-right: 16px; }
.iq-accordion .ad-details { display: none; overflow: hidden; text-align: left; padding: 15px 15px; color: #666666; line-height: 24px; border: 1px solid #eeeeee; border-top: none; -webkit-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06); }
.iq-accordion .iq-ad-block { margin-bottom: 30px; }
.iq-accordion .iq-ad-block:last-child { margin-bottom: 0; }

/*---------------------------------------------------------------------
                        Price Table
-----------------------------------------------------------------------*/
.iq-pricing { position: relative; display: inline-block; width: 100%; border: 1px solid #ededed; background: #fff; }
.iq-pricing:before { position: absolute; bottom: 0; left: 0; height: 282px; width: 100%; opacity: 0.08; content: ""; background: url('../images/about/03.jpg') no-repeat 0 0; }
.iq-pricing .price-title { padding: 20px 30px; }
.iq-pricing .price-title h1 { font-family: 'Open Sans', sans-serif; line-height: 60px; font-size: 50px; }
.iq-pricing .price-title h1 small { font-size: 16px; color: #fff; vertical-align: super; padding: 0 5px; }
.iq-pricing .price-title h1 small:first-child { font-size: 24px; }
.iq-pricing .price-title span { letter-spacing: 6px; }
.iq-pricing ul { margin: 20px 0; }
.iq-pricing ul li { line-height: 50px; }
.iq-pricing .price-footer { border-top: 1px solid #ededed; padding: 30px 0; z-index: 9; position: relative; }

/*---------------------------------------------------------------------
                                BLOG
-----------------------------------------------------------------------*/
.iq-blog-box { -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06); -moz-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06); box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06); }
.iq-blog-box .iq-blog-image img { width: 100%; }
.iq-blog-detail { padding: 20px 15px 15px; }
.iq-blog-detail a, .iq-blog-detail  .blog-title a h5 { color: #666; transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; }
.iq-blog-detail a:hover, .iq-blog-detail  .blog-title a:hover h5 { color: rgba(225, 35, 32, 0.9); }
.iq-blog-meta { margin: 15px 0; }
.blog-button { display: inline-block; width: 100%; border-top: 1px solid #ededed; padding: 15px 0 0; }
.blog-button .iq-user i { font-size: 24px; margin-right: 10px; float: left; }

/*---------------------------------------------------------------------
                           Our clients
-----------------------------------------------------------------------*/
.iq-our-clients { border-top: 1px solid #ededed; }
.iq-our-clients .owlcarousel .owl-nav { margin-top: -20px; }

/*---------------------------------------------------------------------
                             Newsletter
-----------------------------------------------------------------------*/
.iq-newsletter .form-inline { text-align: center; }
.iq-newsletter .form-group { width: 40%; }
.iq-newsletter .form-control { border-radius: 90px; height: 50px; width: 100%; display: inline-block; padding-left: 25px; box-shadow: none; border: none; }
.newsletter-phone { margin-bottom: -180px; }

/*---------------------------------------------------------------------
                             Our Info
-----------------------------------------------------------------------*/
.iq-info-box { padding: 25px; }
.iq-info-box .info-icon { border-radius: 90px; height: 70px; width: 70px; display: inline-block; line-height: 70px; text-align: center; color: #ffffff; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -webkit-transition: all 0.5s ease-out 0s; -moz-transition: all 0.5s ease-out 0s; -ms-transition: all 0.5s ease-out 0s; -o-transition: all 0.5s ease-out 0s; transition: all 0.5s ease-out 0s; }
.iq-info-box .info-icon i { font-size: 30px; }
.info-share { margin: 30px 0 0; padding: 30px 0 0; border-top: 1px solid #ededed; text-align: center; }
.info-share li { display: inline-block; list-style: none; padding: 0; margin: 0px 5px; }
.info-share li a { display: block; width: 45px; height: 45px; line-height: 45px; font-size: 16px; color: #666; background: transparent; border: 1px solid #666; text-align: center; border-radius: 50%; }
.info-share li a:hover { background: rgba(225, 35, 32, 0.9); border-color: rgba(225, 35, 32, 0.9); color: #fff; }

/*---------------------------------------------------------------------
                             Map
-----------------------------------------------------------------------*/
.iq-map iframe { border: 0px; width: 100%; height: 350px; }

/*---------------------------------------------------------------------
                             Get in Touch
-----------------------------------------------------------------------*/
.iq-get-in { position: relative; margin-top: -12%; -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); }
.contact-form .section-field { position: relative; width: 100%; float: left; }
.contact-form .section-field input { position: relative; width: 100%; margin-bottom: 20px; border-radius: 90px; padding-left: 20px; height: 48px; line-height: 48px; clear: both; color: #b6b6b6; border: 1px solid #e1e1e1; }
.contact-form .section-field input:focus, .contact-form .section-field.textarea textarea:focus { border: 1px solid rgba(225, 35, 32, 0.9); box-shadow: none; outline: none; }
.contact-form .section-field.textarea textarea { width: 100%; float: left; color: #b6b6b6; border: 1px solid #e1e1e1; border-radius: 20px; padding: 15px 0 0 20px; resize: none; }
#formmessage { display: none; }

/*---------------------------------------------------------------------
                             Footer
-----------------------------------------------------------------------*/
.footer-copyright { border-top: 1px solid #ededed; }
.footer-info { padding: 0 40px; }
.footer-info img { height: 80px; }

/*---------------------------------------------------------------------
                           Breadcrumb Inner Page
-----------------------------------------------------------------------*/
.iq-breadcrumb { margin: 0px; position: relative; display: inline-block; width: 100%; }
.iq-breadcrumb:before { content: ""; position: absolute; top: 0; left: 0px; width: 100%; height: 100%; opacity: 0.8; background: rgba(225, 35, 32, 0.7); background: -moz-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -webkit-gradient(left top, right top, color-stop(0%, rgba(225, 35, 32, 0.7)), color-stop(100%, rgba(225, 35, 32, 0.9))); background: -webkit-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -o-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: -ms-linear-gradient(left, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); background: linear-gradient(to right, rgba(225, 35, 32, 0.7) 0%, rgba(225, 35, 32, 0.9) 100%); filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#09b3ef', endColorstr='#1e50e2', GradientType=1); }
.iq-breadcrumb-title .title { letter-spacing: 2px; text-transform: uppercase; }
.iq-breadcrumb-title .title span { font-family: 'Open Sans', sans-serif; }
.iq-breadcrumb .breadcrumb { background: rgba(255, 255, 255, 1); padding: 10px 25px; border-radius: 90px; display: inline-block; position: relative; bottom: -20px; margin-bottom: 0; -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); }
.iq-breadcrumb .breadcrumb li a { color: #2c3e50; }
.iq-breadcrumb .breadcrumb li a i { padding-right: 5px; }
.iq-breadcrumb .breadcrumb li a:hover { color: rgba(225, 35, 32, 0.9); }
.iq-breadcrumb .breadcrumb li { color: #2c3e50; font-size: 16px; font-weight: 500; }
.iq-breadcrumb .breadcrumb li.active { color: rgba(225, 35, 32, 0.9); }
.iq-breadcrumb .breadcrumb>li+li:before { color: #2c3e50; }

/*pagination-nav*/
.pagination { margin: 0px; }
.pagination li a { margin: 0px; border-radius: 0px; color: #666; }
.pagination > li > a, .pagination > li > span { padding: 10px 18px; }
.pagination li a:focus, .pagination li a:hover, .pagination li span:focus, .pagination li span:hover { border-color: rgba(225, 35, 32, 0.7); background-color: rgba(225, 35, 32, 0.7); color: #fff; }
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover { background-color: rgba(225, 35, 32, 0.9); border-color: rgba(225, 35, 32, 0.9); color: #fff; }
.pagination-lg>li:last-child>a, .pagination-lg>li:last-child>span { border-bottom-right-radius: 90px; border-top-right-radius: 90px; }
.pagination-lg>li:first-child>a, .pagination-lg>li:first-child>span { border-bottom-left-radius: 90px; border-top-left-radius: 90px; }

/*---------------------------------------------------------------------
                              Blog Page
-----------------------------------------------------------------------*/
.iq-page-blog .iq-blog-box { box-shadow: none; border: 1px solid #ededed; }
.iq-blog-box .iq-get-in { margin-top: 0; box-shadow: none; }
.iq-post-author { position: relative; min-height: 130px; }
.iq-post-author-pic { float: left; }
.iq-post-author a { color: #fff; }

/* Comments Box */
.iq-comment-list li { margin-top: 25px; margin-bottom: 0; vertical-align: top; padding: 0; }
.iq-comment-list .iq-comments-media { padding: 15px 15px 30px; border-radius: 0px; border-bottom: 1px solid #eee; }
.iq-comment-list li:last-child .iq-comments-media { border-bottom: none; padding-bottom: 0; }
.iq-comment-list ul { padding-left: 60px; }
.iq-comment-list .iq-comments-photo { padding-right: 20px; }
.iq-comment-list .iq-comments-photo a img { width: 80px; height: auto; }
.iq-comment-list .iq-comments-info { position: relative; }
.iq-comment-list .iq-comments-info .title { margin: 0; line-height: 22px; }
.iq-comment-list .iq-comment-metadata { line-height: 22px; margin-top: 7px; margin-bottom: 10px; }
.iq-comment-list .iq-comment-metadata a { color: #333333; }
.iq-comment-list .iq-comment-metadata i { padding-right: 7px; color: rgba(225, 35, 32, 0.9); }
.iq-comment-list .reply { position: absolute; line-height: 22px; margin: 0; padding: 2px 16px; font-size: 11px; right: 0; top: 0; font-weight: 500; }
.iq-comment-list .iq-comments-media .iq-comments-info .reply { color: #ffffff; }
.iq-comment-list .iq-comments-info p { margin-top: 5px; margin-bottom: 0; }

/*---------------------------------------------------------------------
                             Blog - SideBar
-----------------------------------------------------------------------*/
.iq-post-sidebar { height: 100%; }
.iq-sidebar-widget { margin-bottom: 40px; padding: 20px 20px; border: 1px solid #ededed; border-radius: 0px; }
.iq-sidebar-widget:last-child { margin-bottom: 0; }
.iq-sidebar-widget .iq-widget-title { padding-bottom: 10px; margin-bottom: 20px; font-size: 24px; }

/*widget-menu*/
.iq-widget-menu { position: relative; }
.iq-widget-menu ul li a { font-size: 14px; display: block; color: #2c3e50; position: relative; }
.iq-widget-menu i { padding-top: 4px; position: absolute; right: 18px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; }
.iq-widget-menu ul li a span { padding: 5px 10px 5px 0; display: block; font-size: 14px; }
.iq-widget-menu ul li a span:hover { text-decoration: none; color: rgba(225, 35, 32, 0.9); }
.iq-widget-menu ul li.active { border-bottom: none; }
.iq-widget-menu ul li.hover a { color: rgba(225, 35, 32, 0.9); }
.iq-widget-menu ul li.hover a span { background: transparent; color: rgba(225, 35, 32, 0.9); }
.iq-widget-menu ul ul li a span { color: #333333; }
.iq-widget-menu ul ul li a span:hover { color: rgba(225, 35, 32, 0.9); }
.iq-widget-menu ul ul { display: none; padding-left: 20px; }
.iq-widget-menu ul ul a { display: block; color: #333333; font-size: 14px; }
.iq-widget-menu ul ul a:hover { color: rgba(225, 35, 32, 0.9); }
.iq-widget-menu ul li.active i { transform: rotate(90deg); }

/*SideBar - Search*/
.iq-sidebar-widget .iq-widget-search { position: relative; }
.iq-sidebar-widget .iq-widget-search input { padding-right: 40px; box-shadow: none; border-radius: 90px; color: #2c3e50; height: 50px; border: 2px solid #f2f2f2; background: #ffffff; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; }
.iq-sidebar-widget .iq-widget-search input:focus { background: #ffffff; }
.iq-sidebar-widget .iq-widget-search i { position: absolute; right: 8px; color: rgba(225, 35, 32, 0.9); cursor: pointer; padding: 16px 12px; font-size: 18px; }
.iq-sidebar-widget .iq-widget-search input:focus { border-color: rgba(225, 35, 32, 0.9); }

/*SideBar - Posts*/
.iq-sidebar-widget .iq-recent-post { margin-top: 20px; }
.iq-sidebar-widget .iq-recent-post .media-body > a { display: block; font-size: 15px; font-weight: 600; color: #2c3e50; }
.iq-sidebar-widget .iq-recent-post .media-body > a:hover { color: rgba(225, 35, 32, 0.9); }
.iq-sidebar-widget .iq-recent-post .media-body span { color: #666666; }
.iq-sidebar-widget .iq-recent-post .media-body span .fa { color: rgba(225, 35, 32, 0.7); margin-right: 5px; }

/*SideBar - Tags*/
.iq-tags li { padding-left: 2px; display: inline-block; padding-right: 2px; margin: 0 0 15px; }
.iq-tags li a { background: transparent; color: #666; padding: 4px 8px; border: 1px solid #666; border-radius: 90px; transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; }
.iq-tags li a:hover { border-color: rgba(225, 35, 32, 0.9); background: none; color: rgba(225, 35, 32, 0.9); }

/* Meta - SideBar */
.iq-widget-archives li { margin: 10px 0; }
.iq-widget-archives li a { color: #666; }
.iq-widget-archives li a i { font-size: 16px; margin-right: 5px; }
.iq-widget-archives li a:hover { margin-left: 5px; color: rgba(225, 35, 32, 0.9); }

/*---------------------------------------------------------------------
                             404 Error
-----------------------------------------------------------------------*/
.iq-error .big-text { font-size: 180px; font-family: 'Open Sans', sans-serif; line-height: 180px; color: #333333; }
.iq-error h6 { background-color: rgba(225, 35, 32, 0.9); color: #ffffff; display: inline-block; }
.iq-error .form-inline.iq-subscribe { text-align: center; }
.iq-error .iq-subscribe .form-group { width: 40%; }
.iq-error .iq-subscribe .form-control { border-radius: 90px; height: 50px; width: 100%; display: inline-block; padding-left: 25px; box-shadow: none; border: none; background: #f4f4f4; }

/*---------------------------------------------------------------------
                            Coming Soon
-----------------------------------------------------------------------*/
.iq-coming .big-text { font-size: 80px; font-family: 'Open Sans', sans-serif; text-transform: uppercase; color: #333333; }
.iq-coming img { width: 140px; }
.iq-coming .form-inline.iq-subscribe { text-align: center; }
.iq-coming .iq-subscribe .form-group { width: 40%; }
.iq-coming .iq-subscribe .form-control { border-radius: 90px; height: 50px; width: 100%; display: inline-block; padding-left: 25px; box-shadow: none; border: none; background: #f4f4f4; }
.iq-coming .countdown-timer { border-radius: 5px; margin-bottom: 20px; max-width: 300px; margin: 50px auto; }
.iq-coming .countdown-timer h5 { font-size: 14px; letter-spacing: 0.5px; text-align: center; padding-top: 10px; text-shadow: none; }
.iq-coming .countdown-timer .timer { padding: 10px; text-align: center; padding-top: 15px; }
.iq-coming .countdown-timer .timer .timer-wrapper { display: inline-block; width: 200px; height: 50px; }
.iq-coming .countdown-timer .timer .timer-wrapper .time { font-size: 80px; font-weight: bold; color: #333; margin: 0 50px; float: left; }
.iq-coming .countdown-timer .timer .timer-wrapper .text { font-size: 20px; }
#countdown { list-style: none; margin: 50px 0; padding: 0; display: block; text-align: center; display: inline-block; }
#countdown li { display: inline-block; }
#countdown li span { font-size: 50px; font-weight: 800; line-height: 80px; margin: 0 30px; }
#countdown li.seperator { font-size: 50px; line-height: 40px; vertical-align: top; margin-top: 15px; }
#countdown li p { color: #a7abb1; font-size: 20px; }


/* Partie dashboard */
/* .content-page { overflow: hidden; margin-left: 60px; padding: 5px 1px 0; min-height: 100vh; -webkit-transition: all 0.3s ease-out 0s; -moz-transition: all 0.3s ease-out 0s; -ms-transition: all 0.3s ease-out 0s; -o-transition: all 0.3s ease-out 0s; transition: all 0.3s ease-out 0s; } */
.iq-card-body.relative-background { position: relative; overflow: hidden; }
.iq-card-body.banner-box { padding-bottom: 0; }


table tr th img { width: 35px; height: 35px; border-radius: 90px; }

/* Ajout du css pour le modal de la video*/

.modal-wrapper {
    position: fixed;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -25%);
    background: "#AD2825";
    box-shadow: 8 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    margin: 100px auto 0;
    transition: all .8s;
    width: 60%;
    z-index: 35;
    display: none;
}

.modal-header {
    background: "#D03A38";
    height: 4  0px;
    line-height: 40px;
    padding: 5px 20px;
    text-align: right;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.modal-header h2{
   float: left;
   margin: 0;
   padding: 0;
   color: #fff;
}

.modal-body {
    padding: 5px;
    text-align: center;
    height: 450px;
}

.modal-footer {
   background: #999;
   height: 35px;
   padding: 15px;
   border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.close-modal-btn {
    color: white;
    cursor: pointer; 
    float: right;
    font-size: 30px;
    margin: 0;
}

.close-modal-btn:hover {
    color: black;
}

.btn-cancel { 
    background-color: #b71c1c;
    float: left ;
}

.btn-continue {
    background-color: #1b5c20;
}
.back-shed {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
    top: 0;
    z-index: 20;
}

.video-scr {
    width: 100%;
    height: 100%;
    border: none !important;
    box-shadow: none !important;
}

@media(max-width : 1100px) {
    .modal-wrapper {
        width: 95%;
        position: fixed;
    }
}

@media(max-width : 700px) {
    .modal-body {height: 240px;}
    .modal-header h2 {font-size: 14px;}
    .modal-footer {height: 20px;}
    .modal-footer p{font-size: 14px;}
}